import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "using-canvas"
    }}>{`Using canvas`}</h1>
    <p>{`So far we've been rendering our visualizations with SVG. SVG is great because
it follows a familiar structure, offers infinitely scalable vector graphics,
and works everywhere. There are `}<em parentName="p">{`some`}</em>{` advanced SVG features you can't use
everywhere, but the core is solid.`}</p>
    <p>{`However, SVG has a big flaw: it's slow.`}</p>
    <p>{`Anything more than a few hundred SVG nodes and your browser starts to struggle.
Especially if those thousands of elements move around.`}</p>
    <p>{`A web animation panel moderator at ForwardJS once asked me, `}<em parentName="p">{`"But why would you
want thousands of SVG elements?"`}</em>{`.`}</p>
    <p>{`It was my first time participating in a panel, stage lights shining upon me, a
mildly disinterested audience staring into their phones ... I bombed: `}<em parentName="p">{`"Errr
... because you can?"`}</em>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/2018/stage_lights.jpg",
        "alt": null
      }}></img></p>
    <p>{`What I `}<em parentName="p">{`should`}</em>{` have said was: `}<em parentName="p">{`"Because there have been thousands of UFO
sightings, there are thousands of counties in the US, millions of taxi rides,
hundreds of millions of people having this or that datapoint. And you want to
show change over time."`}</em></p>
    <p>{`That's the real answer.`}</p>
    <p>{`Sometimes, when you're visualizing data, you have a lot of data. The data
changes over time. Animation is the best way to show change over time.`}</p>
    <p>{`Once upon a time, I worked on a
`}<a parentName="p" {...{
        "href": "https://www.packtpub.com/web-development/mastering-d3js-video"
      }}>{`D3 video course`}</a>{`
for Packt and used UFO sightings as an example. At peak UFO sighting, right
before smartphones become a thing, the animation takes up to 2 seconds to
redraw a single frame.`}</p>
    <p>{`Terrible.`}</p>
    <p>{`So if SVG is slow and you need to animate thousands of elements, what are you
to do? HTML5 Canvas.`}</p>
    <h2 {...{
      "id": "why-canvas"
    }}>{`Why Canvas`}</h2>
    <p>{`Unlike SVG, HTML5 Canvas lets you draw rasterized images. This means you're no
longer operating at the level of shapes because you're working with pixels on
the screen.`}</p>
    <p>{`With SVG and other vector formats, you tell the browser `}<em parentName="p">{`what`}</em>{` you want to
render. With Canvas and other raster formats, you tell the browser `}<em parentName="p">{`how`}</em>{` you
want to render. The browser doesn't know what you're doing; it gets a field of
pixel colors and renders them as an image.`}</p>
    <p>{`That's much faster for computers to handle. In some cases browsers can even use
hardware acceleration – the GPU – to render HTML5 Canvas elements. With a bit
of care, you can do almost anything you want, even on a mobile phone.`}</p>
    <p>{`Phones these days have amazing GPUs and kind of terrible CPUs in comparison.
The CPU burns more battery, works slower, warms up your phone more, etc.`}</p>
    <p>{`If SVG wasn't so easy to use, I'd almost suggest going straight to Canvas for
any sort of complex animation. Mobile traffic is, what, 60% to 70% of web
traffic these days?`}</p>
    <h2 {...{
      "id": "isnt-that-too-hard"
    }}>{`Isn't that too hard?`}</h2>
    <p>{`You might think all this pixel stuff sounds complicated. We've stayed in shape
and component land so far. We didn't care about any pixels or rendering
details. Draw a rectangle and a wild rectangle appears.`}</p>
    <p>{`How do you know which pixel should do what when you render with Canvas?`}</p>
    <p>{`HTML5 Canvas does offer some shape primitives. It has circles and rectangles
and things like that, but they suffer from the same problem that SVG does. The
browser has to use your CPU to calculate those, and at around 10,000 elements,
things break down.`}</p>
    <p>{`10,000 elements is still a hell of a lot more than the 3,000 or so that SVG
gives you.`}</p>
    <p>{`If your app allows it, you can use sprites: Tiny images copy-pasted on the
Canvas as bytestreams. I have yet to find an upper bound for those. My
JavaScript became the bottleneck 😄`}</p>
    <p>{`But I'm getting ahead of myself. We'll talk about sprites later.`}</p>
    <h2 {...{
      "id": "the-trouble-with-html5-canvas"
    }}>{`The trouble with HTML5 Canvas`}</h2>
    <p>{`The tricky thing with HTML5 Canvas is that the API is low level and that canvas
is flat. As far as your JavaScript and React code are concerned, it's a flat
image. It could be anything.`}</p>
    <p>{`The lack of structure makes it difficult to detect clicks on elements,
interactions between shapes, when something covers something else, how the user
interacts with your stuff and so on Anything that requires understanding what's
rendered.`}</p>
    <p>{`You have to move most of that logic into your data store and manually keep
track.`}</p>
    <p>{`As you can imagine, this becomes cumbersome. And you still can't detect user
interaction because all you get is `}<em parentName="p">{`"User clicked on coordinate (x, y). Have
fun."`}</em></p>
    <p>{`At the same time, the low level API makes abstractions difficult. You can't
create components for "this is a map" or "histogram goes here". You're always
down to circles and rectangles and basic shapes.`}</p>
    <p>{`Your code soon looks like the D3.js spaghetti we tried to avoid in the first
place.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      